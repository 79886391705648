<template>
  <div>
    <md-button @click="showDialog = true">Crear nuevo</md-button>
    <md-dialog :md-active.sync="showDialog">
    <md-dialog-title>Nueva Rosca</md-dialog-title>
    <div class="md-layout md-alignment-center margin-fix">
      <p>Introduzca datos para crear una Rosca</p>
      <div class="md-layout-item md-size-80">
        <md-field>
          <label>Descripción de la Rosca</label>
          <md-input v-model="$v.screw.description.$model" />
        </md-field>
        <div v-if="$v.screw.description.$dirty">
          <span class="md-helper-text" v-if="!$v.screw.description.required">Campo requerido</span>
          <span class="md-helper-text" v-if="!$v.screw.description.minLength">Minimo 2 caracteres</span>
          <span class="md-helper-text" v-if="!$v.screw.description.maxLength">Maximo de caracteres</span>
        </div>
      </div>
    </div>
    <md-dialog-actions class="margin-fix">
      <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
      <md-button class="md-primary" @click="onConfirm">Crear</md-button>
    </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import validations from '@/validations/productScrews'
export default {
  name: 'Create',
  data() {
    return {
      showDialog: false,
      screw: {
        description: '',
      }
    }
  },
  mounted() {
    Object.assign(this.$data, this.$options.data.apply(this))
  },
  validations: validations,
  methods: {
    onConfirm () {
      if (!this.$v.$invalid) {
        this.$store.dispatch('screws/addScrew', this.screw)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.screw = this.$options.data.apply(this).screw
      this.$v.$reset()
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
