import {required, maxLength, minLength} from 'vuelidate/lib/validators'

export default {
  screw: {
    description: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(25)
    }
  }
}
